<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" sm="12" md="4">
          <v-card min-height="200">
            <v-card-title>
              <v-icon left color="primary">
                mdi-teach
              </v-icon>
              Cursos
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="text--primary pt-0 pb-0 pl-0 pr-0">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr class="azul">
                      <th class="text-center white--text">
                        Programas
                      </th>
                      <th class="text-center white--text">
                        Cantidad Cursos
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in actual_courses"
                      :key="index"
                      class="text-center"
                    >
                      <td>{{ item.program }}</td>
                      <td>{{ item.conteo }}</td>
                    </tr>
                  </tbody>
                  <tfoot class="primary--text border-1">
                    <tr class="grey lighten-2 text-center">
                      <td>
                        <b>Total</b>
                      </td>
                      <td>
                        {{ getSum(actual_courses, "conteo") }}
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-card min-height="200">
            <v-card-title>
              <v-icon left color="primary">
                mdi-account
              </v-icon>
              Alumnos Activos
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="text--primary pt-0 pb-0 pl-0 pr-0">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr class="azul">
                      <th class="text-center white--text">
                        Programas
                      </th>
                      <th class="text-center white--text">
                        Cantidad Alumnos
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in actual_students"
                      :key="index"
                      class="text-center"
                    >
                      <td>{{ item.program }}</td>
                      <td>{{ item.conteo }}</td>
                    </tr>
                  </tbody>
                  <tfoot class="primary--text border-1">
                    <tr class="grey lighten-2 text-center">
                      <td>
                        <b>Total</b>
                      </td>
                      <td>
                        {{ getSum(actual_students, "conteo") }}
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" sm="12" md="4">
          <v-card min-height="200">
            <v-card-title>
              <v-icon left color="primary">
                mdi-plus
              </v-icon>
              Nuevo Ingreso
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="text--primary pt-0 pb-0 pl-0 pr-0">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr class="azul">
                      <th class="text-center white--text">
                        Examen
                      </th>
                      <th class="text-center white--text">
                        Cantidad Alumnos
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in new_students"
                      :key="index"
                      class="text-center"
                    >
                      <td>{{ item.status | getStatus }}</td>
                      <td>{{ item.conteo }}</td>
                    </tr>
                  </tbody>
                  <tfoot class="primary--text border-1">
                    <tr class="grey lighten-2 text-center">
                      <td>
                        <b>Total</b>
                      </td>
                      <td>
                        {{ getSum(new_students, "conteo") }}
                      </td>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  name: "HomePrivate",
  data: () => ({
    actual_courses: [],
    actual_students: [],
    new_students: [],
  }),
  mounted() {
    this.getActualCourses();
    this.getActualStudents();
    this.getNewsStudents();
  },
  methods: {
    //actions
    ...mapActions({
      openSnackbar: "Snackbar/openSnackbar",
    }),
    //funcionalidad
    getActualCourses: function() {
      this.$http
        .get(this.api + "stadistic/course")
        .then(({ data }) => {
          this.actual_courses = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        });
    },
    getActualStudents: function() {
      this.$http
        .get(this.api + "stadistic/active_student")
        .then(({ data }) => {
          this.actual_students = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        });
    },
    getNewsStudents: function() {
      this.$http
        .get(this.api + "stadistic/new_student")
        .then(({ data }) => {
          this.new_students = data;
        })
        .catch((err) => {
          this.openSnackbar({
            type: "danger",
            message: "Error al obtener los datos",
          });
        });
    },

    getSum(arr, position) {
      let suma = 0;
      if (arr.length > 0) {
        arr.forEach((item) => {
          if (item[position]) {
            suma += parseInt(item[position]);
          }
        });
      }
      return suma;
    },
  },
  computed: {
    ...mapGetters({
      api: "getApi",
    }),
  },
  filters: {
    getStatus(val) {
      switch (parseInt(val)) {
        case 1:
          return "Activo";
        case 2:
          return "Desactivado";
        case 3:
          return "Nuevo Ingreso (Examen)";
        case 4:
          return "Nuevo Ingreso";

        default:
          return "?";
      }
    },
  },
};
</script>

<style></style>
